<template>
  <b-container class="view-container" fluid>
    <div class="d-flex flex-column justify-content-center my-2">
      <p class="p4">
        Para ver o Extrato de Fundo de Sustentabilidade basta selecionar ano de
        competência e clicar no botão abaixo “ver extrato”.
      </p>
      <b-card class="custom-card flex-grow-1 mt-4 mb-4">
        <div v-show="!this.loading">
          <div
            v-if="!competencyYearsOptions || competencyYearsOptions.length <= 0"
            class="text-center"
          >
            <a>Nenhuma competência encontrada!</a>
          </div>

          <b-row v-else class="flex-center align-items-center">
            <b-col>
              ANO DE COMPETÊNCIA
            </b-col>
            <b-col>
              <b-form-select
                v-model="selectedOption"
                :options="competencyYearsOptions"
                size="lg"
              />
            </b-col>
          </b-row>
        </div>
        <div v-show="this.loading">
          <b-skeleton-table :rows="1" :columns="1"></b-skeleton-table>
        </div>
      </b-card>
    </div>

    <b-row class="mt-auto">
      <b-button
        class="m-3"
        variant="primary"
        v-b-modal:sustainability-fund-modal
        v-show="
          !this.loading &&
            competencyYearsOptions &&
            competencyYearsOptions.length > 0
        "
      >
        Ver extrato
      </b-button>
    </b-row>

    <sustainability-fund-modal :competencyDate="selectedOption" />

    <SuccessModal
      description="Solicitação de alteração enviada!"
      buttonText="Ok, entendi!"
    />
  </b-container>
</template>

<script>
import SuccessModal from "../../../../components/base-modals/SuccessModal.vue";
import SustainabilityFundModal from "../modal/SustainabilityFundModal.vue";
import { getSustainabilityFundCompetencyYears } from "../../../../services/sustainability-fund/sustainability-fund-services";
import { DocumentType } from "../../../../static-data/DocumentType.js";

export default {
  name: "sustainability-fund-tab",
  components: {
    SuccessModal,
    SustainabilityFundModal,
  },
  data() {
    return {
      selectedOption: null,
      selectOptions: [],
      competencyYearsOptions: [],
      loading: true,
    };
  },
  mounted() {
    this.getCompetenceYears();
  },
  methods: {
    async getCompetenceYears() {
      try {
        this.loading = true;
        const crm = this.$store.getters.getUserName;

        const competencyOptions = await getSustainabilityFundCompetencyYears(
          crm,
          DocumentType.SUPPORT_FUND
        );

        this.competencyYearsOptions = [
          ...new Set(competencyOptions.map((item) => item.year)),
        ].map((data) => ({
          text: `${data}`,
          value: data,
        }));

        this.selectedOption = this.competencyYearsOptions[0].value;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("Error on function getCompetenceYears: ", error);
      }
    },
  },
};
</script>

<style lang="scss">
.view-container.container-fluid {
  display: flex;
  flex-direction: column;
  max-width: 100rem;
  height: 60vh;
}
</style>
