<template>
  <div>
    <AlterFormLayout
      modalId="sustainability-fund-modal"
      formTitle="Visualização do Extrato de Fundo de Sustentabilidade"
      cancelButtonText="Fechar"
      submitButtonText="Baixar extrato em PDF"
      :onSubmitEvent="downloadPdf"
      :disableValidation="true"
      :landscape="true"
    >
      <template #content>
        <pdf-viewer
          :landscape="true"
          :getDocumentHandler="getDocumentHandler"
        />
      </template>
    </AlterFormLayout>
  </div>
</template>

<script>
import PdfViewer from "../../../../components/pdf-viewer/PdfViewer.vue";
import AlterFormLayout from "../../../../layouts/AlterFormLayout.vue";
import { downloadBase64File } from "../../../../services/pdf-download/pdf-download-services";
import { anchorBase64Download } from "../../../../utils/anchor-base64-download/anchor-base64-download";

export default {
  props: ["competencyDate"],
  data() {
    return {
      downloadedFile: null,
      fileUrl: null,
    };
  },
  components: {
    AlterFormLayout,
    PdfViewer,
  },
  methods: {
    downloadPdf() {
      if (!this.downloadedFile) return;
      anchorBase64Download(
        this.$store.getters.isApp,
        "application/pdf",
        "fundo-sustentabilidade.pdf",
        this.downloadedFile
      );
    },
    async getDocumentHandler() {
      const download = await this.downloadFile();
      this.downloadedFile = download;
      const result = atob(download);
      return result;
    },
    async downloadFile() {
      try {
        const competency = this.$props.competencyDate;
        const response = await downloadBase64File(
          this.$store.getters.getUserName,
          "SUPPORT_FUND",
          competency
        );

        this.fileUrl = response.fileUrl;
        return response.document;
      } catch (error) {
        console.error("Error on function downloadFile:", error);
      }
    },
  },
};
</script>
